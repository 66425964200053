import React from "react";
import AppVersion from "../utils/appVersion"

export default class VersionCheck extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            isLastesVersion: false,
            refresh: () => {
                if (caches) {
                    // Reload service worker
                    caches.keys().then(function(names) {
                        for (let name of names) caches.delete(name);
                    });
                }
                // Reload browser
                window.location.reload(true);
            }
        }

    }

    componentDidMount() {
        const disableApiCall = true; // Set this to true to disable the API call

        if (disableApiCall) {
            // Skip the API call and set the state directly
            this.setState({ loading: false, isLatestVersion: true });
            return;
        }

        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = new AppVersion(meta.version);
                const appVersion = new AppVersion(global.appVersion);

                if (latestVersion.isNewerThan(appVersion)) {
                    this.setState({ loading: false, isLatestVersion: false });
                } else {
                    this.setState({ loading: false, isLatestVersion: true });
                }
            });
    }

    render(){
        const { loading, isLatestVersion, refresh } = this.state;
        return this.props.children({ loading, isLatestVersion, refresh });
    }
}