import { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Affix, Button } from 'antd';
import InfoModal from '../infoModal/infoModal';
import vialto_logo from '../../assets/img/vialto_logo.svg'
import { BaseLayoutContext } from './BaseLayoutProvider';
import { makeRequest } from 'features/communication/request';

const BaseLayout = (props) => {
  const {setUserRole} = useContext(BaseLayoutContext)
  const [isPrivacyOpened, setIsPrivacyOpened] = useState(false);
  const [isCookiesOpened, setIsCookiesOpened] = useState(false);
  const [isAdmin, setAdmin] = useState()
  const location = useLocation()

  const items = [
    {
      label:  <Link to="/AdminAction">AdminAction</Link>,
      key: '0',
    },
    {
      label:  <Link to="/AdminConsole">AdminConsole</Link>,
      key: '0',
    },
    {
      label: <Link to="/upload-document?customer=all">Upload Document</Link>,
      key: '1',
    },
    {
      label: <Link to="/admin/kibana">Kibana</Link>,
      key: '2',
    },

  ];
  const { showSettingsButton, changeSettingsWindowVisibility, userRole } = useContext(BaseLayoutContext);

  const openPrivacy = () => {
    setIsPrivacyOpened(true);
    setIsCookiesOpened(false);
  };

  const openCookies = () => {
    setIsPrivacyOpened(false);
    setIsCookiesOpened(true);
  };

  const handleOk = () => {
    setIsPrivacyOpened(false);
    setIsCookiesOpened(false);
  };

  const handleCancel = () => {
    setIsPrivacyOpened(false);
    setIsCookiesOpened(false);
  };

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      makeRequest({
        url: 'api/v3/user',
        method: 'get',
      }).then((res) => {
        try {
          setAdmin(res.data.userRole);
          sessionStorage.setItem('mail', res.data.user.email);
          if (res.data.userRole === 'Admin') {
            sessionStorage.setItem('isAdmin', res.data.userRole);
          }
        } catch (error) {
          console.error('Error setting user role:', error);
        }
      })
    }
  }, [sessionStorage.getItem('token')])

  return (
    <div className="base-layout">
      <Affix offsetTop={0} className="sticky-nav">
        <div className="nav-actions">
          <div className="left-nav">
            <div></div>
            <div className='logo-nav'>
              <img src={vialto_logo} alt="vialto logo" />
            </div>
            <div className="my-trips-plus-nav"><Link to="/">myTrips</Link><span className='plus'>+</span></div>
          </div>
          <div className="right-nav">
            {showSettingsButton && (
              <a
                style={{ fontSize: '14px',color:'#1CDBBC', marginRight: '20px' }}
                onClick={changeSettingsWindowVisibility}
              >
                Delegation
              </a>
            )}
            {/* sessionStorage.getItem('isAdmin') === 'Admin' && <Dropdown
              menu={{
                items,
              }}
              trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Admin
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown> */}
            &nbsp;&nbsp; <Link to="/logout">Sign out</Link>
          </div>
        </div>
      </Affix>
      <InfoModal
        type={isPrivacyOpened ? 'privacy' : isCookiesOpened ? 'cookies' : null}
        visible={isPrivacyOpened || isCookiesOpened}
        onOk={handleOk}
        onCancel={handleCancel}
      />

      {props.children}
      <div className="footer">
        <div></div>
        <div>
          <div className="footer-copyrights">
            <div>
              © {new Date().getFullYear()} Vialto Partners. All rights reserved. Please see{' '}
              <a href="https://vialto.com/copyright" target="_blank" rel="noreferrer">vialto.com/copyright</a> for further details
            </div>
            <div className="links">
              <div className="link" onClick={openCookies}>
                Cookies
              </div>
              <div className="link" onClick={openPrivacy}>
                Privacy
              </div>
              {process.env.NODE_ENV === 'development' && <div>
                Version: {global.appVersion}
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseLayout;
