import React, { Component } from 'react';
import BaseLayout from '../layouts/baseLayout/baseLayout';
import Logout from '../components/logout/Logout';

class LogoutPage extends Component{

  render(){
    return (
        <BaseLayout>
            <Logout />
        </BaseLayout>
    );
  }
}

export default LogoutPage;