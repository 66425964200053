import React, { Component } from 'react';
import BaseLayout from '../layouts/baseLayout/baseLayout';
import MyTripsForm from '../components/form3/MyTripsForm';
import BaseLayoutProvider from '../layouts/baseLayout/BaseLayoutProvider';

class MyTrips extends Component {
  render() {
    return (
      <BaseLayoutProvider>
        <BaseLayout>
          <MyTripsForm {...this.props} />
        </BaseLayout>
      </BaseLayoutProvider>
    );
  }
}

export default MyTrips;
