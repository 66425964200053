import axios from 'axios';
import { Modal } from 'antd';
import * as actionsAuth from '../../redux/actions/auth';
import { connect } from 'react-redux';
import {logout} from "../../redux/actions/auth";

export const makeRequest = (property, hostName = process.env.REACT_APP_BACKEND_API) => {
  if (property.hostname) {
    hostName = property.hostname;
  }

  const token = 'Bearer ' + sessionStorage.getItem('token');

  axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
  axios.defaults.xsrfCookieName = 'csrftoken';

  let config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? token : '',
      ...property.headers,
    },
    params: property.params ? property.params : '',
  };

  switch (property.method) {
    case 'auth':
      return axios.post(hostName + property.url, property.body).catch((error) => {
        Modal.error({
          title: 'Error Auth',
          content: error.response ? error.response.data : error.message,
        });
        return null;
      });

    case 'get':
      return axios.get(property.url ? hostName + property.url : hostName, config).catch(commonCatch);

    case 'options':
      return axios.options(hostName + property.url, config).catch(commonCatch);

    case 'download':
      return axios
        .get(hostName + property.url, { ...config, responseType: 'blob' })
        .catch(commonCatch);
    case 'postdownload':
          return axios
            .post(hostName + property.url, property.body,{ ...config, responseType: 'blob' })
            .catch(commonCatch);    

    case 'post':
      return axios.post(property.url ? hostName + property.url : hostName, property.body, config).catch(commonCatch);

    case 'put':
      return axios.put(hostName + property.url, property.body, config).catch(commonCatch);

    case 'delete':
      return axios.delete(hostName + property.url, config).catch(commonCatch);

    default:
      console.warn('method type not exist');
      return Promise.resolve(null);
  }
};

function commonCatch(error) {

  //console.log('I am in common catch with error', error);
  if (error.response && error.response.status === 401) {

    localStorage.removeItem('code');
    sessionStorage.removeItem('token');
    localStorage.removeItem('userRole');
    //actionsAuth.logout();
    this.props.onTryAutoSignup();
  } else {
   
    //console.log('ERROR', error);
    //console.log('ERROR', error.response);
    //Modal.error({
    //  title: error.response ? 'Error: ' + error.response.config.url : 'Error: ',
    //  content: error.response ? error.response.data.MSG : error.message,
    //});
  }
  throw error;
}

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actionsAuth.authCheckState()),
  };
};

export default connect(mapDispatchToProps)(commonCatch);
